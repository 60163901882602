import CreateAppointment from "../../../features/Customer/CreateAppointment";

export default function GuestAppointment() {
  return (
    <>
      <CreateAppointment />
    </>
  );


}